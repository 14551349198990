import { Chip, readableDate, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { quickScore } from 'quick-score';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { TablePage } from '../../../components/tablePage/TablePage';
import { TDeliveryMethod } from '../../../generated/graphql';
import { COMPANY_PATH, EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH, EMPLOYEES_PATH } from '../../../routes/paths';

import styles from './Employees.module.scss';
import { TEmployeeType, useQueryAllEmployees } from '../../../entities/employees/queries';
import { useFilter } from '../../../contexts/filters';
import clsx from 'clsx';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';

export const Employees = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const navigate = useNavigate();
  const { data: employees, error, loading } = useQueryAllEmployees();

  const companies = useMemo(() => {
    return _.uniqBy(_.compact(employees.map((e) => e.company)), 'id');
  }, [employees]);

  const [companyFilter, setCompanyFilter] = useState<string>();
  const [employeeNameFilter, setEmployeeNameFilter] = useState<string>();

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [
      {
        includeEmptyOption: true,
        type: 'singleSelect',
        label: 'Company',
        name: 'companyId',
        onChange: (v) => {
          if (v == null) setCompanyFilter(undefined);
          else setCompanyFilter(v as string);
        },
        options: companies.map((c) => ({ label: c.name, value: c.id })),
        value: companyFilter,
      },
      {
        type: 'textInput',
        label: 'Employee Name',
        name: 'employeeName',
        onChange: (v) => {
          if (v == null) setEmployeeNameFilter(undefined);
          else setEmployeeNameFilter(v as string);
        },
        value: employeeNameFilter,
      },
    ];

    return filtersList;
  }, [companies, companyFilter, employeeNameFilter]);

  const filteredEmployees = useMemo(() => {
    return employees.filter((e) => {
      if (companyFilter != null && e.companyId !== companyFilter) {
        return false;
      }

      if (
        employeeNameFilter != null &&
        employeeNameFilter !== '' &&
        (e.fullName == null || quickScore(e.fullName, employeeNameFilter) < 0.7)
      ) {
        return false;
      }

      return true;
    });
  }, [companyFilter, employeeNameFilter, employees]);

  const getRowId = useCallback((employee: TEmployeeType) => {
    return employee.id;
  }, []);

  const onRowClick = useCallback(
    (employee: TEmployeeType) => {
      navigate(
        generatePath(`${EMPLOYEES_PATH}/${COMPANY_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId: employee.id,
          companyId: employee.companyId,
        }),
      );
    },
    [navigate],
  );

  return (
    <TablePage dataLoading={loading} error={error} title='Employees'>
      <Filters filters={filters} />
      <TableInner<TEmployeeType>
        data={filteredEmployees}
        emptyText={employees.length === 0 ? 'No employees registered.' : 'No employees for the current filters.'}
        expandable={false}
        fillSelectedRows={false}
        fixedHeader={true}
        getRowId={getRowId}
        hideSubRowsSelect={true}
        hoverableRows={true}
        selectableRows={false}
        onRowClick={(row) => {
          onRowClick(row.original);
        }}
        virtual={true}
        columns={[
          {
            header: 'Company',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}> {cell.row.original.company?.name || '-'}</div>
              );
            },
          },
          {
            header: 'Full Name',
            cell: ({ cell }) => {
              const {
                address,
                birthDate,
                company: { deliveryMethod, employeeBirthdayActivated } = {},
                hireDate,
                deletedAt,
              } = cell.row.original;
              return (
                <div className={clsx(styles.rowNameColumn)}>
                  <span>{cell.row.original.fullName}</span>
                  {_.isEmpty(address) && deliveryMethod === TDeliveryMethod.Home && (
                    <Chip
                      className={styles.errorChip}
                      label='Missing address'
                      readonly={true}
                      selected={true}
                      variant='suggestion'
                    />
                  )}
                  {deletedAt && (
                    <Tooltip message={`Deleted on ${readableDate(deletedAt, 'MMM do, yyyy')}`}>
                      <Chip
                        className={styles.errorChip}
                        label='Deleted'
                        readonly={true}
                        selected={true}
                        variant='suggestion'
                      />
                    </Tooltip>
                  )}
                  {(hireDate == null || hireDate === '') && (
                    <Chip
                      className={styles.errorChip}
                      label='Missing hire date'
                      readonly={true}
                      selected={true}
                      variant='suggestion'
                    />
                  )}
                  {(birthDate == null || birthDate === '') && employeeBirthdayActivated && (
                    <Chip
                      className={styles.errorChip}
                      label='Missing birth date'
                      readonly={true}
                      selected={true}
                      variant='suggestion'
                    />
                  )}
                </div>
              );
            },
          },
          {
            header: 'Email',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span> {cell.row.original.email || '-'} </span>
                </div>
              );
            },
          },
          {
            header: 'Hire Date',
            cell: ({ cell }) => {
              if (cell.row.original.hireDate == null) return '-';
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.hireDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Birth Date',
            accessorKey: 'birthDate',
            cell: ({ cell }) => {
              if (cell.row.original.birthDate == null) return '-';
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.birthDate)}</span>
                </div>
              );
            },
          },
        ]}
      />
    </TablePage>
  );
};
